<template>
  <div
    v-if="page.length"
    class="relative"
  >
    <img
      :src="isMobile ? page[0].img_m : page[0].img"
      alt=""
      class="width-100"
    >
    <div
      class="width-100"
      style="height:310px;background:#EAF2FA"
    />

    <div
      class="bg-fff"
      :class="isMobile ? 'mlr10 ptb50 relative z1' : 'absolute-center flex-center p50'"
      :style="isMobile ? 'margin-top:-490px; margin-bottom: 46px;' : ''"
    >
      <div
        :style="isMobile ? '' : 'width:490px;'"
        :class="isMobile ? 'tc mlr30' : 'bd br bd-eee pr40'"
      >
        <div class="f32">
          {{ page[0].t1 }}
        </div>
        <div
          class="dib relative z1"
          style="width: 30px;height: 2px;background: #ed7989;"
        />
        <div
          class="f18 mt50"
          style="font-weight:lighter"
        >
          {{ page[0].t2 }}
        </div>
      </div>

      <div
        :class="isMobile ? 'mt20' : 'pl50'"
      >
        <div
          class="relative flex items-center"
          :class="isMobile ? 'mlr20' : 'pr50'"
          style="background:#F6F6F6;"
        >
          <div class="plr20 h50 flex-center nowrap">
            防伪码
          </div>
          <input
            v-model="fwcode"
            type="text"
            class="h50"
            style="border:none;background:transparent;"
            placeholder="请输入16位数字防伪码"
          >
        </div>

        <div
          class="relative flex items-center"
          :class="isMobile ? 'mlr20 mt10' : 'mt10'"
          style="background:#F6F6F6;"
        >
          <div class="plr20 h50 flex-center nowrap">
            验证码
          </div>
          <input
            v-model="captcha"
            type="text"
            class="h50"
            style="border:none;background:transparent;width: 100%;"
            placeholder="请输入右侧图形验证码"
          >
          <div class=" h50 flex-center nowrap">
            <img :src="captchaImg" @click="exchange();" />
          </div>
        </div>

        <div
          :class="isMobile ? 'mt40 flex-center flex-column' : 'flex-center-between mt50 flex-direction-row-reverse'"
        >
          <div class="bg-000 plr20 ptb10 c-fff cp"
               @click="query"
          style="">
            立即查询
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import http from "@/mixins/http";
import '@/plugins/element-ui'

export default {
  mixins: [http],
  data() {
    return {
      page: [],
      fwcode:'',
      captcha:'',
      captchaImg:'/service/api/captcha.php'
    }
  },

  async created () {
    const { data } = await this.fetch('/fangwei.json');
    this.page = data;
  },

  methods: {
    exchange() {
      this.captchaImg = this.captchaImg + "?r=" + Math.random();
    },

    query(){
      this.fwcode = this.fwcode.trim();
      this.captcha = this.captcha.trim();

      if (this.fwcode === null || this.fwcode === '' || this.fwcode.length !== 16){
        this.$alert('请输入16位数字防伪码', {
          confirmButtonText: '确定',
          showClose:false,
          type: "error",
        });
      }else if(this.captcha === null || this.captcha === '' || this.captcha.length !== 4){
        this.$alert('请输入4位图形防伪码', {
          confirmButtonText: '确定',
          showClose:false,
          type: "error",
        });
      }else{
        this.http('query', {fwcode: this.fwcode, captcha: this.captcha}).then(res=> {
          console.log(res)
          console.log(res.data.code);
          let msg = '';
          let type = '';

          if (res.data.code === 3001){
            type = 'error';
          }else if (res.data.code === 3002){
            type = 'error';
          }else if (res.data.code === 3003){
            type = 'success';
          }else if (res.data.code === 3004){
            type = 'success';
          }else{
            type = 'error';
          }

          msg = res.data.msg;

          this.$alert(msg, {
            confirmButtonText: '确定',
            showClose:false,
            dangerouslyUseHTMLString:true,
            type: type,
          });

          this.captchaImg = this.captchaImg + "?r=" + Math.random();
        });
      }
    }
  }
}
</script>

<style>
.el-message-box{ max-width: 420px!important; width: 90% !important; padding-bottom: 20px!important;
  vertical-align: baseline!important }
</style>
