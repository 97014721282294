import axios from 'axios'
import qs from 'qs'

// post data格式为默认form表单格式
let instance = axios.create({
  baseURL: '/',
  // baseURL: '/service', //生产环境
  timeout: 3600000, //1小时
  withCredentials: true,
  paramsSerializer: function(params) {
    return qs.stringify(params, {arrayFormat: 'brackets'})
  },
})

instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  return config
}, function (error) {
  return Promise.reject(error)
})

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  return response;
  // if (response.data.code === 200) {
  //   return response.data
  // } else {
  //   // alert(response.data.msg)
  //   return Promise.reject(response)
  // }
}, function (error) {
  return Promise.reject(error)
})

// a switch
let mock = false;
let getMockUrl = (str)=> {
  let index = str.lastIndexOf('/')
  return `./static${str.substr(index, str.length)}.json`
}

let get = (uri, data = {})=> {
  if (mock || data.mock) {
    uri = getMockUrl(uri)
  }
  return instance.get(uri, {
    params: data
  })
}

let post = (uri, data = {})=> {
  if (mock || data.mock) {
    uri = getMockUrl(uri)
  }
  return instance.post(uri, data)
}

// let uri = 'ccs/YuYinFW';
let uri = '';//生产环境
export default {
  url: {
    query: uri + '/service/api/code.php?act=query',
  },

  query(data) {
    return post(this.url.query, data)
  }
}
