import resource from '../resource'

export default {
  data() {
    return {
    }
  },

  methods: {
    http(uri, data) {
      let loadingService = this.$loading({
        text: '业务处理中...',
        background: 'rgba(0, 0, 0, 0.8)'
      })
      return new Promise((resolve, reject)=> {
        resource[uri](data).then(res=> {
          loadingService.close();
          if (res.code === 601) {
            // login invalid
            this.$router.push('/login')
          } else {
            resolve(res)
          }
        }).catch(error=> {
          loadingService.close()
          this.$message.error(error.msg || '网络错误')
          reject(error)
        })
      })
    }
  }
}


