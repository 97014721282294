import Vue from 'vue';

import {
  Container,
  Header,
  Aside,
  Main,
  Avatar,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Alert,
  Select,
  Option,
  OptionGroup,
  Input,
  InputNumber,
  Button,
  Loading,
  MessageBox,
  Message,
  Notification
} from 'element-ui';

Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Avatar);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Alert);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Button);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
